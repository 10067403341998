import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47')
];

export const server_loads = [0,2,3];

export const dictionary = {
		"/(marketing)": [~21,[4]],
		"/(marketing)/8vc": [~22,[4]],
		"/(marketing)/blog": [~23,[4]],
		"/(marketing)/blog/[category]": [~24,[4]],
		"/(marketing)/blog/[category]/[slug]": [~25,[4]],
		"/(booking)/book": [~6,[2]],
		"/(booking)/book/care-channel": [~7,[2]],
		"/(booking)/book/confirmed": [~8,[2]],
		"/(booking)/book/demographics": [~9,[2]],
		"/(booking)/book/error": [~10,[2]],
		"/(booking)/book/insurance": [~11,[2]],
		"/(booking)/book/insurance/add": [~12,[2]],
		"/(booking)/book/insurance/confirm": [~13,[2]],
		"/(booking)/book/insurance/upload": [14,[2]],
		"/(booking)/book/review": [~15,[2]],
		"/(booking)/book/time": [~16,[2]],
		"/(marketing)/careers": [26,[4]],
		"/(marketing)/choose": [27,[4]],
		"/(marketing)/core/business": [~28,[4]],
		"/(marketing)/core/employees": [~29,[4]],
		"/(marketing)/core/employees/activate": [~30,[4,5]],
		"/(marketing)/core/employees/activate/error": [31,[4,5]],
		"/(marketing)/core/employees/activate/welcome": [32,[4,5]],
		"/(marketing)/core/service-details": [33,[4]],
		"/(marketing)/infusion-forms": [~34,[4]],
		"/(marketing)/insurances-accepted": [~35,[4]],
		"/(marketing)/locations": [~36,[4]],
		"/(marketing)/locations/[slug]": [~37,[4]],
		"/(marketing)/member-support": [38,[4]],
		"/(legal)/no-surprises": [17,[3]],
		"/(legal)/notice-of-privacy-practices": [18,[3]],
		"/(marketing)/partner": [39,[4]],
		"/(marketing)/partner/core": [40,[4]],
		"/(marketing)/partner/mobile-clinics": [41,[4]],
		"/(marketing)/press": [~42,[4]],
		"/(marketing)/press/[slug]": [~43,[4]],
		"/(legal)/privacy-policy": [19,[3]],
		"/(marketing)/providers": [~44,[4]],
		"/(marketing)/providers/[slug]": [~45,[4]],
		"/(marketing)/services/[slug]": [~46,[4]],
		"/(legal)/terms-of-use": [20,[3]],
		"/(marketing)/trs": [47,[4]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: universal_hooks.reroute || (() => {})
};

export { default as root } from '../root.svelte';