import type { Reroute } from '@sveltejs/kit';

const routesToRedirect: Record<string, string> = {
  '/8VC': '/8vc',
};

// https://svelte.dev/docs/kit/hooks#Universal-hooks-reroute
export const reroute: Reroute = ({ url }) => {
  if (url.pathname in routesToRedirect) {
    return routesToRedirect[url.pathname];
  }
};
